.c-fff {
  color: @c-fff;
}
.c-333 {
  color: @c-333;
}
.c-666 {
  color: @c-666;
}
.c-999 {
  color: @c-999;
}

.bg-ff {
  background-color: @c-fff;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.fBlod {
  font-weight: bold;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.inline {
  display: inline;
}
.inlineBlock {
  display: inline-block;
}
.block {
  display: block;
}
.vMiddle {
  vertical-align: middle;
}
.flexBox{
  display: flex;
}
.flexWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flexItem {
  flex: 1;
}
.generate-columns(40);
.generate-columns (@n, @i: 1) when (@i =< @n) {
  .pl@{i} {
    padding-left:(@i / 7.5) + 0vw;
  }
  .pr@{i} {
    padding-right: (@i / 7.5) + 0vw;
  }
  .pt@{i} {
    padding-top: (@i / 7.5) + 0vw;
  }
  .pb@{i} {
    padding-bottom: (@i / 7.5) + 0vw;
  }
  .ml@{i} {
    margin-left: (@i / 7.5) + 0vw;
  }
  .mr@{i} {
    margin-right: (@i / 7.5) + 0vw;
  }
  .mt@{i} {
    margin-top: (@i / 7.5) + 0vw;
  }
  .mb@{i} {
    margin-bottom: (@i / 7.5) + 0vw;
  }
  .generate-columns(@n, (@i + 1));
}
.font-size-cont(38);
.font-size-cont (@n, @i: 12) when (@i =< @n) {
  .fs@{i} {
    font-size: (@i / 7.5) + 0vw;
  }
  .font-size-cont(@n, (@i + 1));
}
