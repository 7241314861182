//变量
@c-333: #333;
@c-666: #666;
@c-999: #999;
@c-fff: #ffffff;
@c-2ff: #3072FF;
@c-202: #D00202;

.homeLayout {
  min-height: 100vh;
}

@blue: @c-2ff;