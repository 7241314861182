/* reset */
* {
  font-family: 'PingFang', '微软雅黑', 'Microsoft YaHei', Arial, sans-serif;
  box-sizing: border-box;
}
html {
  font-size: 0.24rem;
}
html,
body {
  height: 100%;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
}
header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th {
  text-align: left;
  font-weight: normal;
}
html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}
i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}
[hidefocus],
summary {
  outline: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-size: 100%;
}
sup,
sub {
  font-size: 83%;
}
pre,
code,
kbd,
samp {
  font-family: inherit;
}
q:before,
q:after {
  content: none;
}
textarea {
  overflow: auto;
  resize: none;
}
label,
summary {
  cursor: default;
}
a,
button {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
b {
  font-weight: bold;
}
del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}
body,
textarea,
input,
button,
select,
keygen,
legend {
  color: #333;
  outline: 0;
}
body {
  background: #fff;
}
/**
* 对vant 默认样式的覆盖
* https://youzan.github.io/vant/#/zh-CN/theme
*/
.van-toast {
  max-width: 80vw;
  width: auto;
}
.van-field--error .van-field__control,
.van-field--error .van-field__control::placeholder {
  color: #DA2C37;
}
.van-field__error-message {
  color: #DA2C37;
}
.van-field--error .van-field__control::placeholder {
  color: #c8c9cc;
}
.c-fff {
  color: #ffffff;
}
.c-333 {
  color: #333;
}
.c-666 {
  color: #666;
}
.c-999 {
  color: #999;
}
.bg-ff {
  background-color: #ffffff;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.fBlod {
  font-weight: bold;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.inline {
  display: inline;
}
.inlineBlock {
  display: inline-block;
}
.block {
  display: block;
}
.vMiddle {
  vertical-align: middle;
}
.flexBox {
  display: flex;
}
.flexWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flexItem {
  flex: 1;
}
.pl1 {
  padding-left: 0.13333333vw;
}
.pr1 {
  padding-right: 0.13333333vw;
}
.pt1 {
  padding-top: 0.13333333vw;
}
.pb1 {
  padding-bottom: 0.13333333vw;
}
.ml1 {
  margin-left: 0.13333333vw;
}
.mr1 {
  margin-right: 0.13333333vw;
}
.mt1 {
  margin-top: 0.13333333vw;
}
.mb1 {
  margin-bottom: 0.13333333vw;
}
.pl2 {
  padding-left: 0.26666667vw;
}
.pr2 {
  padding-right: 0.26666667vw;
}
.pt2 {
  padding-top: 0.26666667vw;
}
.pb2 {
  padding-bottom: 0.26666667vw;
}
.ml2 {
  margin-left: 0.26666667vw;
}
.mr2 {
  margin-right: 0.26666667vw;
}
.mt2 {
  margin-top: 0.26666667vw;
}
.mb2 {
  margin-bottom: 0.26666667vw;
}
.pl3 {
  padding-left: 0.4vw;
}
.pr3 {
  padding-right: 0.4vw;
}
.pt3 {
  padding-top: 0.4vw;
}
.pb3 {
  padding-bottom: 0.4vw;
}
.ml3 {
  margin-left: 0.4vw;
}
.mr3 {
  margin-right: 0.4vw;
}
.mt3 {
  margin-top: 0.4vw;
}
.mb3 {
  margin-bottom: 0.4vw;
}
.pl4 {
  padding-left: 0.53333333vw;
}
.pr4 {
  padding-right: 0.53333333vw;
}
.pt4 {
  padding-top: 0.53333333vw;
}
.pb4 {
  padding-bottom: 0.53333333vw;
}
.ml4 {
  margin-left: 0.53333333vw;
}
.mr4 {
  margin-right: 0.53333333vw;
}
.mt4 {
  margin-top: 0.53333333vw;
}
.mb4 {
  margin-bottom: 0.53333333vw;
}
.pl5 {
  padding-left: 0.66666667vw;
}
.pr5 {
  padding-right: 0.66666667vw;
}
.pt5 {
  padding-top: 0.66666667vw;
}
.pb5 {
  padding-bottom: 0.66666667vw;
}
.ml5 {
  margin-left: 0.66666667vw;
}
.mr5 {
  margin-right: 0.66666667vw;
}
.mt5 {
  margin-top: 0.66666667vw;
}
.mb5 {
  margin-bottom: 0.66666667vw;
}
.pl6 {
  padding-left: 0.8vw;
}
.pr6 {
  padding-right: 0.8vw;
}
.pt6 {
  padding-top: 0.8vw;
}
.pb6 {
  padding-bottom: 0.8vw;
}
.ml6 {
  margin-left: 0.8vw;
}
.mr6 {
  margin-right: 0.8vw;
}
.mt6 {
  margin-top: 0.8vw;
}
.mb6 {
  margin-bottom: 0.8vw;
}
.pl7 {
  padding-left: 0.93333333vw;
}
.pr7 {
  padding-right: 0.93333333vw;
}
.pt7 {
  padding-top: 0.93333333vw;
}
.pb7 {
  padding-bottom: 0.93333333vw;
}
.ml7 {
  margin-left: 0.93333333vw;
}
.mr7 {
  margin-right: 0.93333333vw;
}
.mt7 {
  margin-top: 0.93333333vw;
}
.mb7 {
  margin-bottom: 0.93333333vw;
}
.pl8 {
  padding-left: 1.06666667vw;
}
.pr8 {
  padding-right: 1.06666667vw;
}
.pt8 {
  padding-top: 1.06666667vw;
}
.pb8 {
  padding-bottom: 1.06666667vw;
}
.ml8 {
  margin-left: 1.06666667vw;
}
.mr8 {
  margin-right: 1.06666667vw;
}
.mt8 {
  margin-top: 1.06666667vw;
}
.mb8 {
  margin-bottom: 1.06666667vw;
}
.pl9 {
  padding-left: 1.2vw;
}
.pr9 {
  padding-right: 1.2vw;
}
.pt9 {
  padding-top: 1.2vw;
}
.pb9 {
  padding-bottom: 1.2vw;
}
.ml9 {
  margin-left: 1.2vw;
}
.mr9 {
  margin-right: 1.2vw;
}
.mt9 {
  margin-top: 1.2vw;
}
.mb9 {
  margin-bottom: 1.2vw;
}
.pl10 {
  padding-left: 1.33333333vw;
}
.pr10 {
  padding-right: 1.33333333vw;
}
.pt10 {
  padding-top: 1.33333333vw;
}
.pb10 {
  padding-bottom: 1.33333333vw;
}
.ml10 {
  margin-left: 1.33333333vw;
}
.mr10 {
  margin-right: 1.33333333vw;
}
.mt10 {
  margin-top: 1.33333333vw;
}
.mb10 {
  margin-bottom: 1.33333333vw;
}
.pl11 {
  padding-left: 1.46666667vw;
}
.pr11 {
  padding-right: 1.46666667vw;
}
.pt11 {
  padding-top: 1.46666667vw;
}
.pb11 {
  padding-bottom: 1.46666667vw;
}
.ml11 {
  margin-left: 1.46666667vw;
}
.mr11 {
  margin-right: 1.46666667vw;
}
.mt11 {
  margin-top: 1.46666667vw;
}
.mb11 {
  margin-bottom: 1.46666667vw;
}
.pl12 {
  padding-left: 1.6vw;
}
.pr12 {
  padding-right: 1.6vw;
}
.pt12 {
  padding-top: 1.6vw;
}
.pb12 {
  padding-bottom: 1.6vw;
}
.ml12 {
  margin-left: 1.6vw;
}
.mr12 {
  margin-right: 1.6vw;
}
.mt12 {
  margin-top: 1.6vw;
}
.mb12 {
  margin-bottom: 1.6vw;
}
.pl13 {
  padding-left: 1.73333333vw;
}
.pr13 {
  padding-right: 1.73333333vw;
}
.pt13 {
  padding-top: 1.73333333vw;
}
.pb13 {
  padding-bottom: 1.73333333vw;
}
.ml13 {
  margin-left: 1.73333333vw;
}
.mr13 {
  margin-right: 1.73333333vw;
}
.mt13 {
  margin-top: 1.73333333vw;
}
.mb13 {
  margin-bottom: 1.73333333vw;
}
.pl14 {
  padding-left: 1.86666667vw;
}
.pr14 {
  padding-right: 1.86666667vw;
}
.pt14 {
  padding-top: 1.86666667vw;
}
.pb14 {
  padding-bottom: 1.86666667vw;
}
.ml14 {
  margin-left: 1.86666667vw;
}
.mr14 {
  margin-right: 1.86666667vw;
}
.mt14 {
  margin-top: 1.86666667vw;
}
.mb14 {
  margin-bottom: 1.86666667vw;
}
.pl15 {
  padding-left: 2vw;
}
.pr15 {
  padding-right: 2vw;
}
.pt15 {
  padding-top: 2vw;
}
.pb15 {
  padding-bottom: 2vw;
}
.ml15 {
  margin-left: 2vw;
}
.mr15 {
  margin-right: 2vw;
}
.mt15 {
  margin-top: 2vw;
}
.mb15 {
  margin-bottom: 2vw;
}
.pl16 {
  padding-left: 2.13333333vw;
}
.pr16 {
  padding-right: 2.13333333vw;
}
.pt16 {
  padding-top: 2.13333333vw;
}
.pb16 {
  padding-bottom: 2.13333333vw;
}
.ml16 {
  margin-left: 2.13333333vw;
}
.mr16 {
  margin-right: 2.13333333vw;
}
.mt16 {
  margin-top: 2.13333333vw;
}
.mb16 {
  margin-bottom: 2.13333333vw;
}
.pl17 {
  padding-left: 2.26666667vw;
}
.pr17 {
  padding-right: 2.26666667vw;
}
.pt17 {
  padding-top: 2.26666667vw;
}
.pb17 {
  padding-bottom: 2.26666667vw;
}
.ml17 {
  margin-left: 2.26666667vw;
}
.mr17 {
  margin-right: 2.26666667vw;
}
.mt17 {
  margin-top: 2.26666667vw;
}
.mb17 {
  margin-bottom: 2.26666667vw;
}
.pl18 {
  padding-left: 2.4vw;
}
.pr18 {
  padding-right: 2.4vw;
}
.pt18 {
  padding-top: 2.4vw;
}
.pb18 {
  padding-bottom: 2.4vw;
}
.ml18 {
  margin-left: 2.4vw;
}
.mr18 {
  margin-right: 2.4vw;
}
.mt18 {
  margin-top: 2.4vw;
}
.mb18 {
  margin-bottom: 2.4vw;
}
.pl19 {
  padding-left: 2.53333333vw;
}
.pr19 {
  padding-right: 2.53333333vw;
}
.pt19 {
  padding-top: 2.53333333vw;
}
.pb19 {
  padding-bottom: 2.53333333vw;
}
.ml19 {
  margin-left: 2.53333333vw;
}
.mr19 {
  margin-right: 2.53333333vw;
}
.mt19 {
  margin-top: 2.53333333vw;
}
.mb19 {
  margin-bottom: 2.53333333vw;
}
.pl20 {
  padding-left: 2.66666667vw;
}
.pr20 {
  padding-right: 2.66666667vw;
}
.pt20 {
  padding-top: 2.66666667vw;
}
.pb20 {
  padding-bottom: 2.66666667vw;
}
.ml20 {
  margin-left: 2.66666667vw;
}
.mr20 {
  margin-right: 2.66666667vw;
}
.mt20 {
  margin-top: 2.66666667vw;
}
.mb20 {
  margin-bottom: 2.66666667vw;
}
.pl21 {
  padding-left: 2.8vw;
}
.pr21 {
  padding-right: 2.8vw;
}
.pt21 {
  padding-top: 2.8vw;
}
.pb21 {
  padding-bottom: 2.8vw;
}
.ml21 {
  margin-left: 2.8vw;
}
.mr21 {
  margin-right: 2.8vw;
}
.mt21 {
  margin-top: 2.8vw;
}
.mb21 {
  margin-bottom: 2.8vw;
}
.pl22 {
  padding-left: 2.93333333vw;
}
.pr22 {
  padding-right: 2.93333333vw;
}
.pt22 {
  padding-top: 2.93333333vw;
}
.pb22 {
  padding-bottom: 2.93333333vw;
}
.ml22 {
  margin-left: 2.93333333vw;
}
.mr22 {
  margin-right: 2.93333333vw;
}
.mt22 {
  margin-top: 2.93333333vw;
}
.mb22 {
  margin-bottom: 2.93333333vw;
}
.pl23 {
  padding-left: 3.06666667vw;
}
.pr23 {
  padding-right: 3.06666667vw;
}
.pt23 {
  padding-top: 3.06666667vw;
}
.pb23 {
  padding-bottom: 3.06666667vw;
}
.ml23 {
  margin-left: 3.06666667vw;
}
.mr23 {
  margin-right: 3.06666667vw;
}
.mt23 {
  margin-top: 3.06666667vw;
}
.mb23 {
  margin-bottom: 3.06666667vw;
}
.pl24 {
  padding-left: 3.2vw;
}
.pr24 {
  padding-right: 3.2vw;
}
.pt24 {
  padding-top: 3.2vw;
}
.pb24 {
  padding-bottom: 3.2vw;
}
.ml24 {
  margin-left: 3.2vw;
}
.mr24 {
  margin-right: 3.2vw;
}
.mt24 {
  margin-top: 3.2vw;
}
.mb24 {
  margin-bottom: 3.2vw;
}
.pl25 {
  padding-left: 3.33333333vw;
}
.pr25 {
  padding-right: 3.33333333vw;
}
.pt25 {
  padding-top: 3.33333333vw;
}
.pb25 {
  padding-bottom: 3.33333333vw;
}
.ml25 {
  margin-left: 3.33333333vw;
}
.mr25 {
  margin-right: 3.33333333vw;
}
.mt25 {
  margin-top: 3.33333333vw;
}
.mb25 {
  margin-bottom: 3.33333333vw;
}
.pl26 {
  padding-left: 3.46666667vw;
}
.pr26 {
  padding-right: 3.46666667vw;
}
.pt26 {
  padding-top: 3.46666667vw;
}
.pb26 {
  padding-bottom: 3.46666667vw;
}
.ml26 {
  margin-left: 3.46666667vw;
}
.mr26 {
  margin-right: 3.46666667vw;
}
.mt26 {
  margin-top: 3.46666667vw;
}
.mb26 {
  margin-bottom: 3.46666667vw;
}
.pl27 {
  padding-left: 3.6vw;
}
.pr27 {
  padding-right: 3.6vw;
}
.pt27 {
  padding-top: 3.6vw;
}
.pb27 {
  padding-bottom: 3.6vw;
}
.ml27 {
  margin-left: 3.6vw;
}
.mr27 {
  margin-right: 3.6vw;
}
.mt27 {
  margin-top: 3.6vw;
}
.mb27 {
  margin-bottom: 3.6vw;
}
.pl28 {
  padding-left: 3.73333333vw;
}
.pr28 {
  padding-right: 3.73333333vw;
}
.pt28 {
  padding-top: 3.73333333vw;
}
.pb28 {
  padding-bottom: 3.73333333vw;
}
.ml28 {
  margin-left: 3.73333333vw;
}
.mr28 {
  margin-right: 3.73333333vw;
}
.mt28 {
  margin-top: 3.73333333vw;
}
.mb28 {
  margin-bottom: 3.73333333vw;
}
.pl29 {
  padding-left: 3.86666667vw;
}
.pr29 {
  padding-right: 3.86666667vw;
}
.pt29 {
  padding-top: 3.86666667vw;
}
.pb29 {
  padding-bottom: 3.86666667vw;
}
.ml29 {
  margin-left: 3.86666667vw;
}
.mr29 {
  margin-right: 3.86666667vw;
}
.mt29 {
  margin-top: 3.86666667vw;
}
.mb29 {
  margin-bottom: 3.86666667vw;
}
.pl30 {
  padding-left: 4vw;
}
.pr30 {
  padding-right: 4vw;
}
.pt30 {
  padding-top: 4vw;
}
.pb30 {
  padding-bottom: 4vw;
}
.ml30 {
  margin-left: 4vw;
}
.mr30 {
  margin-right: 4vw;
}
.mt30 {
  margin-top: 4vw;
}
.mb30 {
  margin-bottom: 4vw;
}
.pl31 {
  padding-left: 4.13333333vw;
}
.pr31 {
  padding-right: 4.13333333vw;
}
.pt31 {
  padding-top: 4.13333333vw;
}
.pb31 {
  padding-bottom: 4.13333333vw;
}
.ml31 {
  margin-left: 4.13333333vw;
}
.mr31 {
  margin-right: 4.13333333vw;
}
.mt31 {
  margin-top: 4.13333333vw;
}
.mb31 {
  margin-bottom: 4.13333333vw;
}
.pl32 {
  padding-left: 4.26666667vw;
}
.pr32 {
  padding-right: 4.26666667vw;
}
.pt32 {
  padding-top: 4.26666667vw;
}
.pb32 {
  padding-bottom: 4.26666667vw;
}
.ml32 {
  margin-left: 4.26666667vw;
}
.mr32 {
  margin-right: 4.26666667vw;
}
.mt32 {
  margin-top: 4.26666667vw;
}
.mb32 {
  margin-bottom: 4.26666667vw;
}
.pl33 {
  padding-left: 4.4vw;
}
.pr33 {
  padding-right: 4.4vw;
}
.pt33 {
  padding-top: 4.4vw;
}
.pb33 {
  padding-bottom: 4.4vw;
}
.ml33 {
  margin-left: 4.4vw;
}
.mr33 {
  margin-right: 4.4vw;
}
.mt33 {
  margin-top: 4.4vw;
}
.mb33 {
  margin-bottom: 4.4vw;
}
.pl34 {
  padding-left: 4.53333333vw;
}
.pr34 {
  padding-right: 4.53333333vw;
}
.pt34 {
  padding-top: 4.53333333vw;
}
.pb34 {
  padding-bottom: 4.53333333vw;
}
.ml34 {
  margin-left: 4.53333333vw;
}
.mr34 {
  margin-right: 4.53333333vw;
}
.mt34 {
  margin-top: 4.53333333vw;
}
.mb34 {
  margin-bottom: 4.53333333vw;
}
.pl35 {
  padding-left: 4.66666667vw;
}
.pr35 {
  padding-right: 4.66666667vw;
}
.pt35 {
  padding-top: 4.66666667vw;
}
.pb35 {
  padding-bottom: 4.66666667vw;
}
.ml35 {
  margin-left: 4.66666667vw;
}
.mr35 {
  margin-right: 4.66666667vw;
}
.mt35 {
  margin-top: 4.66666667vw;
}
.mb35 {
  margin-bottom: 4.66666667vw;
}
.pl36 {
  padding-left: 4.8vw;
}
.pr36 {
  padding-right: 4.8vw;
}
.pt36 {
  padding-top: 4.8vw;
}
.pb36 {
  padding-bottom: 4.8vw;
}
.ml36 {
  margin-left: 4.8vw;
}
.mr36 {
  margin-right: 4.8vw;
}
.mt36 {
  margin-top: 4.8vw;
}
.mb36 {
  margin-bottom: 4.8vw;
}
.pl37 {
  padding-left: 4.93333333vw;
}
.pr37 {
  padding-right: 4.93333333vw;
}
.pt37 {
  padding-top: 4.93333333vw;
}
.pb37 {
  padding-bottom: 4.93333333vw;
}
.ml37 {
  margin-left: 4.93333333vw;
}
.mr37 {
  margin-right: 4.93333333vw;
}
.mt37 {
  margin-top: 4.93333333vw;
}
.mb37 {
  margin-bottom: 4.93333333vw;
}
.pl38 {
  padding-left: 5.06666667vw;
}
.pr38 {
  padding-right: 5.06666667vw;
}
.pt38 {
  padding-top: 5.06666667vw;
}
.pb38 {
  padding-bottom: 5.06666667vw;
}
.ml38 {
  margin-left: 5.06666667vw;
}
.mr38 {
  margin-right: 5.06666667vw;
}
.mt38 {
  margin-top: 5.06666667vw;
}
.mb38 {
  margin-bottom: 5.06666667vw;
}
.pl39 {
  padding-left: 5.2vw;
}
.pr39 {
  padding-right: 5.2vw;
}
.pt39 {
  padding-top: 5.2vw;
}
.pb39 {
  padding-bottom: 5.2vw;
}
.ml39 {
  margin-left: 5.2vw;
}
.mr39 {
  margin-right: 5.2vw;
}
.mt39 {
  margin-top: 5.2vw;
}
.mb39 {
  margin-bottom: 5.2vw;
}
.pl40 {
  padding-left: 5.33333333vw;
}
.pr40 {
  padding-right: 5.33333333vw;
}
.pt40 {
  padding-top: 5.33333333vw;
}
.pb40 {
  padding-bottom: 5.33333333vw;
}
.ml40 {
  margin-left: 5.33333333vw;
}
.mr40 {
  margin-right: 5.33333333vw;
}
.mt40 {
  margin-top: 5.33333333vw;
}
.mb40 {
  margin-bottom: 5.33333333vw;
}
.fs12 {
  font-size: 1.6vw;
}
.fs13 {
  font-size: 1.73333333vw;
}
.fs14 {
  font-size: 1.86666667vw;
}
.fs15 {
  font-size: 2vw;
}
.fs16 {
  font-size: 2.13333333vw;
}
.fs17 {
  font-size: 2.26666667vw;
}
.fs18 {
  font-size: 2.4vw;
}
.fs19 {
  font-size: 2.53333333vw;
}
.fs20 {
  font-size: 2.66666667vw;
}
.fs21 {
  font-size: 2.8vw;
}
.fs22 {
  font-size: 2.93333333vw;
}
.fs23 {
  font-size: 3.06666667vw;
}
.fs24 {
  font-size: 3.2vw;
}
.fs25 {
  font-size: 3.33333333vw;
}
.fs26 {
  font-size: 3.46666667vw;
}
.fs27 {
  font-size: 3.6vw;
}
.fs28 {
  font-size: 3.73333333vw;
}
.fs29 {
  font-size: 3.86666667vw;
}
.fs30 {
  font-size: 4vw;
}
.fs31 {
  font-size: 4.13333333vw;
}
.fs32 {
  font-size: 4.26666667vw;
}
.fs33 {
  font-size: 4.4vw;
}
.fs34 {
  font-size: 4.53333333vw;
}
.fs35 {
  font-size: 4.66666667vw;
}
.fs36 {
  font-size: 4.8vw;
}
.fs37 {
  font-size: 4.93333333vw;
}
.fs38 {
  font-size: 5.06666667vw;
}
