/**
* 对vant 默认样式的覆盖
* https://youzan.github.io/vant/#/zh-CN/theme
*/

@blue: @c-2ff;

.van-toast {
  max-width: 80vw;
  width: auto;
}
.van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
  color: #DA2C37;
}
.van-field__error-message {
  color: #DA2C37;
}

.van-field--error .van-field__control::placeholder {
  color: #c8c9cc;
}
